import http from '@/http'

export default {
  getTemplates (category = 'Fee Street', page = 1, productId = null, sorts=1) {
    return http.get('marketing/templates', {
      timeout: 30000,
      params: {
        category,
        page,
        productId,
        sorts
      }
    })
  }
}
