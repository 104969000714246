<template>
  <div>
    <loader-container class="w-full h-full flex flex-col" :loading="loading">
      <div>
        <form-group class="m-6">
          <label class="uppercase text-sm text-gray-500" for="">Category:</label>
          <span class="uppercase">{{category}}</span>
        </form-group>

        <div class="w-full h-full flex">
          <div class="h-full flex justify-center flex-wrap" >
          <div
            v-for="(template, i) in templates"
            :id = "'index_' + i"
            :key="i"
            class="flex flex-col items-center p-5 cursor-pointer"
            @click="confirmSelect(template.product_id)"
          >
            <div @mouseover="showTemplateDetails(i, template.product_id)" @mouseout="hideTemplateDetails()">
              <iframe
                :srcdoc="getIframeSrc(template)"
                class="pointer-events-none overflow-hidden mb-2 mx-auto p-3 rounded"
                style="height: 360px; width: 500px;"
                :class="{
                'border-accent border-4': value === template.product_id,
                'border-gray-300 border' : value !== template.product_id
              }"
              />
            </div>
            <p>{{ template.productDescription }}</p>
          </div>
        </div>
        </div>
        <pagination class="py-5" :current-page="page" :totalPages="totalPages" @click-page="page = $event" />
      </div>
    </loader-container>
    <div id="tooltipBlock" v-show="showTooltipBlock" class="absolute" @mouseover="showTooltipBlock=true">
      <template-tool-tip :template="templates[templateConfirm_key]" :templateBack="templateBack"></template-tool-tip>
    </div>
  </div>
</template>

<script>
import TemplateService from '@/services/TemplateService'
import Pagination from '@/components/Pagination'
import TemplateToolTip from "@/components/TemplateToolTip";

export default {
  name: 'MarketingTemplates',

  components: {
    Pagination,
    TemplateToolTip
  },

  props: ['value', 'category'],

  data () {
    return {
      loading: false,
      totalPages: 0,
      page: 1,
      templates: [],
      backTemplates: [],
      templateConfirm_key:0,
      showBack: false,
      templateBack:{},
      showTooltipBlock: false
    }
  },

  mounted () {
    this.getTemplates(this.page)
  },

  methods: {
    getIframeSrc (template) {
      return `
        <!DOCTYPE HTML>
        <html>
        <head>
        <style>html,body{padding:0;margin:0;overflow:hidden}${template.css}</style>
        </head>
        <body>${template.markup}</body>
        </html>
      `
    },

    getTemplates (page = 1) {
      this.loading = true
      TemplateService.getTemplates(this.category, page).then(({ data }) => {
        this.templates = data.data
        this.totalPages = data.last_page
        TemplateService.getTemplates(this.category, page, null, 2).then(({ data }) => {
          this.backTemplates = data.data
          this.loading = false
        })
      })
    },

    getBackTemplate(product_id){
      var backTemplate = this.backTemplates.find(function (element){
        return element.product_id === product_id
      })
      return backTemplate
    },

    showTemplateDetails (key, product_id) {
      if(this.loading) {
        return;
      }
      if (this.templateConfirm_key !== key) {
        this.templateConfirm_key = key
        this.templateBack = this.getBackTemplate(product_id)
      }
      this.showTooltipBlock = true;

      var rect = document.getElementById('index_'+key).getBoundingClientRect();
      document.getElementById('tooltipBlock').style.top = rect.top + "px"
      document.getElementById('tooltipBlock').style.left = rect.left + "px"

    },

    hideTemplateDetails () {
      this.showTooltipBlock = false
    },

    confirmSelect (product_id){
      this.$emit('input', product_id)
    }
  },

  watch: {
    page () {
      this.getTemplates(this.page)
    },
    category () {
      this.getTemplates(this.page)
    },
  }
}
</script>
