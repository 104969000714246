<template>
  <div class="border border-gray-200 bg-white text-gray-600 p-3 rounded-md">
    <div class="flex justify-between overflow-x-scroll">
      <router-link
        class="flex items-center p-2 mr-5 flex-shrink-0"
        active-class="bg-accent text-white rounded-md"
        :to="{name: 'region:figures-and-charts'}"
        exact
      >
        <icon name="graph" class="w-8 h-8 mr-2" /> Figures &amp; Charts
      </router-link>

      <router-link
        class="flex items-center p-2 mr-5 flex-shrink-0"
        active-class="bg-accent text-white rounded-md"
        :to="{name: 'region:income-chart'}"
        exact
      >
        <icon name="sterling" class="w-6 h-6 mr-2" /> Income Chart
      </router-link>

      <router-link
        class="flex items-center p-2 mr-5 flex-shrink-0"
        active-class="bg-accent text-white rounded-md"
        :to="{name: 'region:street-summary'}"
        exact
      >
        <icon name="street-marker" class="w-8 h-8 mr-2" /> Street Summary
      </router-link>

      <router-link
        class="flex items-center p-2 mr-5 flex-shrink-0"
        active-class="bg-accent text-white rounded-md"
        :to="{name: 'region:locality-summary'}"
        exact
      >
        <icon name="locality-marker" class="w-8 h-8 mr-2" /> Locality Summary
      </router-link>

      <router-link
        class="flex items-center p-2 mr-5 flex-shrink-0"
        active-class="bg-accent text-white rounded-md"
        :to="{name: 'region:automated-marketing'}"
        exact
        v-if="system.print_enabled"
      >
        <icon name="artwork-multiple" class="w-8 h-8 mr-2" /> Campaign
      </router-link>

      <router-link
        class="flex items-center p-2 flex-shrink-0"
        active-class="bg-accent text-white rounded-md"
        :to="{name: 'region:area-covered'}"
        exact
      >
        <icon name="area-marker" class="w-8 h-8 mr-2" /> Area Covered
      </router-link>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    props: {
      region: null
    },
    computed: {
      ...mapState(['system'])
    }
  }
</script>
