<template>
  <div class="mx-auto container flex flex-col md:flex-row">
    <div class="w-full flex flex-col mb-8 md:mb-0">
      <router-link to="/region/combined" class="mb-8">
        <span class="p-3 rounded-md bg-accent text-white">&lt;&lt; Back to Market Analysis</span>
      </router-link>
      <panel>
        <div class="w-full flex justify-between items-center border-b border-gray-200 pb-2 mb-5">
          <h3 class="font-bold text-lg">Manage Postcodes</h3>
          <button class="flex items-center text-accent focus:outline-none" @click="editingPostcodes = !editingPostcodes">
            {{ editingPostcodes ? 'Close' : 'Edit' }}
            <icon :name="editingPostcodes ? 'close' : 'edit'" class="ml-1 w-5 h-5" />
          </button>
        </div>

        <postcode-districts :edit="editingPostcodes" @close="editingPostcodes = false" />
      </panel>
    </div>
  </div>
</template>

<script>
import PostcodeDistricts from '@/components/PostcodeDistricts'

export default {
  name: 'ManagePostcodes',

  components: {
    PostcodeDistricts
  },

  data () {
    return {
      editingPostcodes: false
    }
  }
}
</script>
