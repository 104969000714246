<template>
  <div>
    <campaign-selector
      click.stop
      @close="campaignSelectorData = null"
      v-if="campaignSelectorData"
      :pcDistrict="campaignSelectorData.pcDistrict"
      :pcSector="campaignSelectorData.pcSector"
      :location="campaignSelectorData.location"
      :count="campaignSelectorData.count"
      :locality="campaignSelectorData.locality"
      @campaignSelected="setLocationHasCampaign"
      @campaignOverlimit="showOverlimitAlert"
      @addingLocation="loadingStreets=true"
      class="absolute right-0"
      :style="{
        right: campaignSelectorPosition.x + 'px',
        top: campaignSelectorPosition.y + 'px'
      }"
    />
    <modal-small class="w-1/2 h-1/2" v-if="showOverLimit" @close="showOverLimit = false">
      <div class="p-4 w-full">
        <div class="flex">
          <icon name="attention" class="w-10 h-10 mr-4" />
          <h2 class="text-2xl font-bold mb-4">Location Not Saved!</h2>
        </div>
        <p class="text-left mb-4">It appears you're trying to add new location to a campaign that's over your spend limit. Please follow the link below to fill out the form, to request your spend limit to be increased, or close this message to keep your original limit without adding the new location.</p>
        <div class="w-full flex items-center justify-center">
          <a class="block p-2 rounded text-white bg-accent hover:bg-accent" href="https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=e5db8411-28f2-4972-9d03-c7bfb00ca167&env=na3&acct=87f0312d-cc6d-4581-8346-2312d857bb1f&v=2" target="_blank" rel="noopener noreferrer">Request Increased Limit</a>
        </div>
      </div>
    </modal-small>
    <panel class="mb-5">
      <loader-container :loading="loadingStreets">
        <div class="flex w-full">
          <div class="flex-1">
            <h3 class="text-lg font-bold mb-3">
              Top Performing Streets
              <span class="text-sm font-normal">(Collated over the last 5 years)</span>
            </h3>

            <div class="flex items-center border-b py-2">
              <icon name="magnify_glass" class="w-5 h-5 mr-2" />
              <input v-model="search" type="text" class="w-full outline-none" name="" placeholder="Search by street name..." />
            </div>
          </div>
          <div class="flex-3 items-end border-b py-2">
            <div v-if="regionFiveYearPercentageIncrease<0" class="flex mb-2">
              <div class="w-5 h-5 rounded mr-3 bg-red-100">
                <icon name="arrow-down" class="w-5 h-5 text-red-600" />
              </div>
              The Region has an Avg. Sold Price of <span class="text-red-400">&nbsp;{{regionFiveYearPercentageIncrease}}%</span> &nbsp; over the last 5 years
            </div>
            <span class="white"> &#128070;</span>&nbsp; Click on each street for more details
          </div>
        </div>

        <table-wrapper :scrollX="true" :alternate="false">
          <table :key="tableKey">
            <tr class="text-xxs text-center uppercase text-gray-600 border-l-4 border-r-4 border-transparent">
              <td class="align-middle min-w-10 text-left">Street</td>
              <td class="align-middle min-w-2">&nbsp;</td>
              <td class="align-middle min-w-4">District&nbsp;/<br /> Sector</td>
              <td class="align-middle min-w-6">Total No. Houses</td>
              <td class="align-middle min-w-6 cursor-pointer" @click="sortStreets('count')">
                <div class="flex items-center justify-center">
                  No. Of Transactions
                  <icon v-if="streetSort === '-count'" name="arrow-down" class="w-5 h-5 text-accent" />
                  <icon v-if="streetSort === 'count'" name="arrow-up" class="w-5 h-5 text-accent" />
                </div>
              </td>
              <td class="align-middle min-w-6 cursor-pointer" @click="sortStreets('sum')">
                <div class="flex items-center justify-center">
                  Transactions Total
                  <icon v-if="streetSort === '-sum'" name="arrow-down" class="w-5 h-5 text-accent" />
                  <icon v-if="streetSort === 'sum'" name="arrow-up" class="w-5 h-5 text-accent" />
                </div>
              </td>
              <td class="align-middle min-w-6 cursor-pointer" @click="sortStreets('avg_price')">
                <div class="flex items-center justify-center">
                  Avg Sold Price
                  <icon v-if="streetSort === '-avg_price'" name="arrow-down" class="w-5 h-5 text-accent" />
                  <icon v-if="streetSort === 'avg_price'" name="arrow-up" class="w-5 h-5 text-accent" />
                </div>
              </td>
              <td class="align-middle min-w-6">
                <div class="flex items-center justify-center">
                  Avg Fee
                </div>
              </td>
              <td class="align-middle min-w-9 cursor-pointer" @click="sortStreets('avg_count_pa')">
                <div class="flex items-center justify-center">
                  Avg No. Of Transactions (Per Year)
                  <icon v-if="streetSort === '-avg_count_pa'" name="arrow-down" class="w-5 h-5 text-accent" />
                  <icon v-if="streetSort === 'avg_count_pa'" name="arrow-up" class="w-5 h-5 text-accent" />
                </div>
              </td>
              <td class="align-middle min-w-6 cursor-pointer" @click="sortStreets('street_value_pa')">
                <div class="flex items-center justify-center">
                  Street Value (Per Year)
                  <icon v-if="streetSort === '-street_value_pa'" name="arrow-down" class="w-5 h-5 text-accent" />
                  <icon v-if="streetSort === 'street_value_pa'" name="arrow-up" class="w-5 h-5 text-accent" />
                </div>
              </td>
              <td class="align-middle min-w-6" v-if="system.print_enabled">
                <div class="flex items-center justify-center">
                  Include In Campaign
                </div>
              </td>
            </tr>
            <template v-for="(street, i) in streets">
              <tr
                :key="street.unique_name"
                class="text-sm border-r-4 border-l-4"
                :class="[
                  {
                    'bg-gray-100': i % 2 == 0
                  },
                  'cursor-pointer',
                  street.in_campaign ? 'border-green-300' : 'border-gray-200'
                ]"
                @click="toggleTransactions(street)"
              >
                <td class="text-left">
                  <div class="flex items-center">
                    <icon name="street-marker" class="w-6 h-6 mr-1" />
                    <span class="underline capitalize">{{ street.unique_name }}</span>
                  </div>
                </td>
                <td class="text-center align-middle">
                  <tool-tip v-if="street.pc_unit === ''">
                    No valid postcode was returned for <span class="capitalize">{{ street.name }}</span>
                  </tool-tip>
                </td>
                <td class="text-center text-gray-600 align-middle">{{ street.postcode_area }}</td>
                <td class="text-center text-gray-600 align-middle">
                  <div class="relative flex items-center justify-center">
                    <loader class="relative" size="sm" v-if="street.pc_unit !== '' && ! (regionPropertyCounts[street.postcode_area] && regionPropertyCounts[street.postcode_area][street.pc_unit])" />
                    <span v-else>{{ regionPropertyCounts[street.postcode_area][street.pc_unit] }}</span>
                  </div>
                </td>
                <td class="text-center text-gray-600 align-middle">{{ street.count }}</td>
                <td class="text-center text-gray-600 align-middle">{{ moneyFormat(street.sum, 0) }}</td>
                <td
                  class="text-center text-gray-600 align-middle"
                >
                  {{ moneyFormat(street.avg_price, 0) }}
                </td>
                <td class="text-center text-gray-600 align-middle">{{ moneyFormat(street.avg_fee, 0) }}</td>
                <td class="text-center text-gray-600 align-middle">{{ street.avg_count_pa }}</td>
                <td class="text-center text-gray-600 align-middle">{{ moneyFormat(street.value / 5) }}</td>
                <td class="text-center text-gray-600 align-middle" v-if="system.print_enabled">
                  <button :disabled="regionPropertyCounts[street.postcode_area] === undefined || regionPropertyCounts[street.postcode_area][street.pc_unit] === undefined || street.pc_unit === '' ? true : false" @click.stop="openCampaignSelector($event, street.pc_district, street.pc_sector, street.name, regionPropertyCounts[street.postcode_area][street.pc_unit] || 0, street.locality)" class="w-full flex items-center justify-center focus:outline-none disabled:opacity-25">
                    <icon name="artwork-multiple" class="w-8 h-8" :class="street.in_campaign ? 'text-green-300' : 'text-accent'" />
                  </button>
                </td>
              </tr>
              <tr
                :key="`${street.name}-transaction-view`"
                v-if="street.transactionView"
                :class="{ 'bg-gray-100': i % 2 == 0 }"
              >
                <td colspan="10">
                    <div class="w-full flex">
                      <div :key="`${street.name}-map`" class="w-full h-96">
                        <location-map :location="street.name" :transactions="street.transactions" />
                      </div>
                      <div class="w-full h-96 overflow-y-scroll">
                        <location-transactions :transactions="street.transactions" />
                      </div>
                    </div>
                    <location-yearly :cols="street.yearlyData" :region-avg-diff-rate="street.region_percentage_increase"/>
                </td>
              </tr>
            </template>
            <tr v-if="loadingStreets === false && streets.length === 0">
              <td class="py-5" colspan="10">
                <p class="text-center">No streets could be found.</p>
              </td>
            </tr>
          </table>
        </table-wrapper>
        <Pagination
          @click-page="getTopPerformingStreets"
          :total-pages="getPageCount()"
          :current-page="topPerformingStreetsPage"
          class="text-gray-600"
        />
      </loader-container>
    </panel>

    <div class="mb-5">
      <small>
        <strong class="capitalize">avg fee</strong> &amp;
        <strong class="capitalize">street value</strong> figures are calculated using the commission fee
        <strong>you</strong> have set.
      </small>
    </div>
    <div class="mb-5">
      <small class="mb-5">Although we endeavour to maintain the accuracy and relevance of the data contained within {{systemName}}, the data set is derived from Land Registry and occasionally there may be missing data which could affect the displayed results.</small>
      <br><br>
      <small class="mb-5">This analysis may include multiple streets with the same name in the selected area.</small>
    </div>
  </div>
</template>

<script>
import LocationYearly from '@/components/LocationYearly'
import LocationMap from '@/components/LocationMap'
import RegionService from '@/services/RegionService'
import PostcodeService from '@/services/PostcodeService'
import MoneyUtils from '@/MoneyUtils'
import { mapGetters, mapState } from 'vuex'
import Pagination from '@/components/Pagination'
import LocationTransactions from '@/components/LocationTransactions'
import CampaignSelector from '@/components/CampaignSelector'
import debounce from 'lodash/debounce'
import ToolTip from '@/components/ToolTip'
import ModalSmall from '@/components/ModalSmall'

export default {
  name: 'StreetSummary',
  components: {
    Pagination,
    LocationYearly,
    LocationMap,
    LocationTransactions,
    CampaignSelector,
    ToolTip,
    ModalSmall
  },

  mounted () {
    this.getTopPerformingStreets()
    this.getNumberOfStreets()
  },

  data () {
    return {
      loadingStreets: false,
      streetSort: '-street_value_pa',
      search: null,
      streets: [],
      regionPropertyCounts: {},
      numberOfStreets: 0,
      topPerformingStreetsPage: 1,
      campaignSelectorData: null,
      campaignSelectorPosition: {
        x: 0,
        y: 0
      },
      tableKeyCount: 0,
      regionFiveYearPercentageIncrease: 0,
      showOverLimit: false,
    }
  },

  computed: {
    ...mapGetters(['currentRegion']),
    ...mapState(['propertyTypeFilter', 'system', 'selectedRegions']),
    systemName() {
      return this.system ? this.system.name : 'Fee Street'
    },
    tableKey() {
      return 'table-' + this.tableKeyCount
    }
  },

  methods: {
    moneyFormat: MoneyUtils.format,

    sortStreets (sort) {
      let descending = this.streetSort.startsWith('-')
      let key = descending
        ? this.streetSort.substr(1, this.streetSort.length)
        : this.streetSort

      if (sort === key) {
        this.streetSort = descending ? sort : '-' + sort
        return
      }

      this.streetSort = '-' + sort
    },

    getStreetTransactions (street) {
      let regions = this.currentRegion === 'combined' ? this.selectedRegions : this.currentRegion.id
      return Promise.all([
        RegionService.getStreetTransactions(regions, street.name, street.locality, this.propertyTypeFilter, street.pc_district, street.pc_sector),
        RegionService.getYearlyStreetData(regions, street.name, street.locality, this.propertyTypeFilter, street.pc_district, street.pc_sector)
      ]).then(([transactions, yearly]) => {
        this.$set(street, 'transactions', transactions.data)
        this.$set(street, 'yearlyData', yearly.data)
      })
    },

    getNumberOfStreets () {
      let regions = this.currentRegion === 'combined' ? this.selectedRegions : this.currentRegion.id
      RegionService.getNumberOfStreets(regions, this.propertyTypeFilter, this.search)
        .then((request) => {
          this.numberOfStreets = request.data
        })
    },

    getTopPerformingStreets (page = 1) {
      this.loadingStreets = true
      let regions = this.currentRegion === 'combined' ? this.selectedRegions : this.currentRegion.id
      RegionService.getTopPerformingStreets(
        regions,
        this.propertyTypeFilter,
        this.streetSort,
        page,
        this.search
      ).then(({ data }) => {
        this.streets = data
        this.streets.forEach(s => {
          this.$set(s, 'transactions', [])
          this.$set(s, 'transactionView', false)
        })
        this.loadingStreets = false
        this.topPerformingStreetsPage = page
        this.getPropertyCounts()
        this.regionFiveYearPercentageIncrease = this.streets.length > 0 ? this.streets[0].region_percentage_increase : 0
      })
    },

    /**
     * Determine how many pages to display.
     */
    getPageCount () {
      return Math.ceil(this.numberOfStreets / 20)
    },

    /**
     * Get all applicable transactions for a street and display them on the map.
     *
     * @param street Street information.
     */
    toggleTransactions (street) {
      if (street.transactionView) {
        street.transactionView = false
        return
      }

      this.getStreetTransactions(street).then(() => {
        street.transactionView = true
      })
    },

    getPropertyCounts() {
      if (this.currentRegion !== 'combined') {
        return
      }

      let postcodeRegions = {}
      this.streets.forEach(street => {
        if (postcodeRegions[street.postcode_area] === undefined) {
          postcodeRegions[street.postcode_area] = {
            postcodes: [],
            isSector: street.area_type === 'sector'
          }
        }
        postcodeRegions[street.postcode_area].postcodes.push(street.pc_unit)
      })

      for (var postcodeArea in postcodeRegions) {
          let area = postcodeArea
          var postcodesArray = postcodeRegions[postcodeArea].postcodes;
          var postcodeSector = postcodeRegions[postcodeArea].isSector ? postcodeArea : null
          PostcodeService.propertyCounts(postcodesArray, 'street', postcodeSector)
            .then(({ data }) => {
              this.regionPropertyCounts[area] = data.counts
              this.tableKeyCount++
            })
      }
    },

    openCampaignSelector(event, pcDistrict, pcSector, location, count, locality) {
      this.campaignSelectorData = {
        pcDistrict,
        pcSector,
        location,
        count,
        locality
      }

      this.campaignSelectorPosition.y = event.pageY - 60
      this.campaignSelectorPosition.x = document.documentElement.clientWidth - event.pageX + 20
    },

    setLocationHasCampaign () {
      this.loadingStreets = false
      for (let i = 0; i < this.streets.length; i++) {
        if (this.streets[i].pc_district === this.campaignSelectorData.pcDistrict
          && this.streets[i].name === this.campaignSelectorData.location
          && this.streets[i].locality === this.campaignSelectorData.locality) {
            this.streets[i].in_campaign = true
            break;
          }
      }
    },
    showOverlimitAlert () {
      this.loadingStreets = false
      this.showOverLimit = true
    }
  },

  watch: {
    selectedRegions () {
      this.getTopPerformingStreets()
      this.getNumberOfStreets()
    },

    propertyTypeFilter () {
      this.getTopPerformingStreets()
      this.getNumberOfStreets()
    },

    streetSort () {
      this.getTopPerformingStreets()
    },

    search: debounce(function () {
      this.getTopPerformingStreets()
      this.getNumberOfStreets()
    }, 300)
  }
}
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 100%;
  min-height: 35rem;
}
</style>
