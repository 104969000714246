<template>
  <div>
    <campaign-selector
      click.stop
      @close="campaignSelectorData = null"
      v-if="campaignSelectorData"
      :pcDistrict="campaignSelectorData.pcDistrict"
      :pcSector="campaignSelectorData.pcSector"
      :location="campaignSelectorData.location"
      :locality="campaignSelectorData.locality"
      :count="campaignSelectorData.count"
      @campaignSelected="setLocationHasCampaign"
      @campaignOverlimit="showOverlimitAlert"
      @addingLocation="loadingLocalities=true"
      @campaignDuplicateArea="showDuplicateAreaAlert"
      :isLocality="true"
      class="absolute right-0"
      :style="{
        right: campaignSelectorPosition.x + 'px',
        top: campaignSelectorPosition.y + 'px'
      }"
    />
    <modal-small class="w-1/2 h-1/2" v-if="showOverLimit" @close="showOverLimit = false">
      <div class="p-4 w-full">
        <div class="flex">
          <icon name="attention" class="w-10 h-10 mr-4" />
          <h2 class="text-2xl font-bold mb-4">Location Not Saved!</h2>
        </div>
        <p class="text-left mb-4">It appears you're trying to add new location to a campaign that's over your spend limit. Please follow the link below to fill out the form, to request your spend limit to be increased, or close this message to keep your original limit without adding the new location.</p>
        <div class="w-full flex items-center justify-center">
          <a class="block p-2 rounded text-white bg-accent hover:bg-accent" href="https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=e5db8411-28f2-4972-9d03-c7bfb00ca167&env=na3&acct=87f0312d-cc6d-4581-8346-2312d857bb1f&v=2" target="_blank" rel="noopener noreferrer">Request Increased Limit</a>
        </div>
      </div>
    </modal-small>
    <modal-small class="w-1/2 h-1/2" v-if="showDuplicateArea" @close="showDuplicateArea = false">
      <div class="p-4 w-full">
        <div class="flex">
          <icon name="attention" class="w-10 h-10 mr-4" />
          <h2 class="text-2xl font-bold mb-4">Duplicate Area Alert!</h2>
        </div>
        <p class="text-left mb-4">The area you have selected is present in multiple campaigns. If these campaigns are active, then the properties in this area will receive multiple marketing materials. To prevent this either delete or de-active the campaigns with duplicate locations in them or remove the duplicated area from the affected campaigns.</p>
        <div class="w-full flex items-center justify-center">
          <button class="block p-2 rounded text-white bg-accent-600 hover:bg-accent-400" @click="showDuplicateArea = false">Ok, Got it.</button>
        </div>
      </div>
    </modal-small>
    <loader-container :loading="loadingLocalities">
      <panel class="mb-5">
        <div class="flex w-full border-b">
          <div class="flex-1">
            <h3 class="text-lg font-bold mb-3">
              Top Performing Localities
              <span
                class="text-sm font-normal"
              >(Collated over the last 5 years)</span>
            </h3>
          </div>
          <div class="flex-3 items-end py-2">
            <div v-if="regionFiveYearPercentageIncrease<0" class="flex mb-2">
              <div class="w-5 h-5 rounded mr-3 bg-red-100">
                <icon name="arrow-down" class="w-5 h-5 text-red-600" />
              </div>
              The Region has an Avg. Sold Price of <span class="text-red-400">&nbsp;{{regionFiveYearPercentageIncrease}}%</span> &nbsp; over the last 5 years
            </div>
            <span class="white"> &#128070;</span>&nbsp; Click on each street for more details
          </div>
        </div>
        <table-wrapper :scrollX="true" :alternate="false">
          <table :key="tableKey">
            <tr class="text-xxs text-center uppercase text-gray-600 border-l-4 border-r-4 border-transparent">
              <td class="min-w-10 text-left align-middle">Locality</td>
              <td class="min-w-2 align-middle">&nbsp;</td>
              <td class="min-w-4 align-middle">District&nbsp;/<br /> Sector</td>
              <td class="min-w-6 align-middle">Total No. Houses</td>
              <td class="min-w-6 align-middle cursor-pointer" @click="sortLocality('count')">
                <div class="flex items-center justify-center">
                  No. Of Transactions
                  <icon v-if="localitySort === '-count'" name="arrow-down" class="w-5 h-5 text-accent" />
                  <icon v-if="localitySort === 'count'" name="arrow-up" class="w-5 h-5 text-accent" />
                </div>
              </td>
              <td class="min-w-6 align-middle cursor-pointer" @click="sortLocality('sum')">
                <div class="flex items-center justify-center">
                  Transactions Total
                  <icon v-if="localitySort === '-sum'" name="arrow-down" class="w-5 h-5 text-accent" />
                  <icon v-if="localitySort === 'sum'" name="arrow-up" class="w-5 h-5 text-accent" />
                </div>
              </td>
              <td class="min-w-6 align-middle cursor-pointer" @click="sortLocality('avg_price')">
                <div class="flex items-center justify-center">
                  Avg Sold Price
                  <icon v-if="localitySort === '-avg_price'" name="arrow-down" class="w-5 h-5 text-accent" />
                  <icon v-if="localitySort === 'avg_price'" name="arrow-up" class="w-5 h-5 text-accent" />
                </div>
              </td>
              <td class="min-w-6 align-middle">
                <div class="flex items-center justify-center">
                  Avg Fee
                </div>
              </td>
              <td class="min-w-9 align-middle cursor-pointer" @click="sortLocality('avg_count_pa')">
                <div class="flex items-center justify-center">
                  Avg No. Of Transactions Per Year
                  <icon v-if="localitySort === '-avg_count_pa'" name="arrow-down" class="w-5 h-5 text-accent" />
                  <icon v-if="localitySort === 'avg_count_pa'" name="arrow-up" class="w-5 h-5 text-accent" />
                </div>
              </td>
              <td class="min-w-6 align-middle cursor-pointer" @click="sortLocality('locality_value_pa')">
                <div class="flex items-center justify-center">
                  Locality Value (Per Year)
                  <icon v-if="localitySort === '-locality_value_pa'" name="arrow-down" class="w-5 h-5 text-accent" />
                  <icon v-if="localitySort === 'locality_value_pa'" name="arrow-up" class="w-5 h-5 text-accent" />
                </div>
              </td>
              <td class="align-middle min-w-6" v-if="system.print_enabled">
                <div class="flex items-center justify-center">
                  Include In Campaign
                </div>
              </td>
            </tr>
            <template v-for="(locality, i) in localities">
              <tr
                :key="locality.name"
                class="text-sm cursor-pointer border-l-4 border-r-4"
                :class="[
                  { 'bg-gray-100': i % 2 == 0 },
                  locality.in_campaign ? 'border-green-300' : 'border-gray-200'
                ]"
                @click="toggleTransactions(locality)"
              >
                <td class="text-left align-middle">
                  <div class="flex items-center">
                    <icon name="locality-marker" class="w-6 h-6 mr-1" />
                    <span class="underline capitalize">{{ locality.name }}</span>
                  </div>
                </td>
                <td class="text-center align-middle">
                  <tool-tip v-if="regionJointLocalityCounts[locality.postcode_area] !== undefined && regionJointLocalityCounts[locality.postcode_area][locality.pc_unit] !== undefined && system.print_enabled">
                    This and {{ regionJointLocalityCounts[locality.postcode_area][locality.pc_unit] }} other localities are combined by Royal Mail so can only be printed together
                  </tool-tip>
                  <tool-tip v-if="locality.pc_unit === ''">
                    No valid postcode was returned for <span class="capitalize">{{ locality.name }}</span>
                  </tool-tip>
                </td>
                <td class="text-center text-gray-600 align-middle">{{ locality.postcode_area }}</td>
                <td class="text-center text-gray-600 align-middle">
                  <div class="relative flex items-center justify-center">
                    <loader class="relative" size="sm" v-if="locality.pc_unit !== '' && ! (regionPropertyCounts[locality.postcode_area] && regionPropertyCounts[locality.postcode_area][locality.pc_unit])" />
                    <span v-else class="mr-2">{{ regionPropertyCounts[locality.postcode_area][locality.pc_unit] }}</span>
                  </div>
                </td>
                <td class="text-center text-gray-600 align-middle">{{ locality.count }}</td>
                <td class="text-center text-gray-600 align-middle">{{ moneyFormat(locality.sum, 0) }}</td>
                <td
                  class="text-center text-gray-600 align-middle"
                >
                  {{ moneyFormat(locality.avg_price) }}
                </td>
                <td
                  class="text-center text-gray-600 align-middle"
                >{{ moneyFormat(locality.avg_fee, 0) }}</td>
                <td class="text-center text-gray-600 align-middle">{{ locality.avg_count_pa }}</td>
                <td class="text-center text-gray-600 align-middle">{{ moneyFormat(locality.value / 5) }}</td>
                <td class="text-center text-gray-600 align-middle" v-if="system.print_enabled">
                  <button @click.stop="openCampaignSelector($event, locality.pc_district, locality.pc_sector, locality.name, locality.locality, regionPropertyCounts[locality.postcode_area][locality.pc_unit] || 0)" class="w-full flex items-center justify-center">
                    <icon name="artwork-multiple" class="w-8 h-8" :class="locality.in_campaign ? 'text-green-300' : 'text-accent-600'"  />
                  </button>
                </td>
              </tr>
              <tr
                :key="`${locality.name}-transaction-view`"
                v-if="locality.transactionView"
                :class="{ 'bg-gray-100': i % 2 == 0 }"
              >
                <td colspan="10">
                    <div class="w-full flex">
                      <div :key="`${locality.name}-map`" class="w-full h-96">
                        <location-map :location="locality.name" :transactions="locality.transactions" />
                      </div>
                      <div class="w-full h-96 overflow-y-scroll">
                        <location-transactions :transactions="locality.transactions" />
                      </div>
                    </div>
                    <location-yearly :cols="locality.yearlyData" :region-avg-diff-rate="locality.region_percentage_increase" />
                </td>
              </tr>
            </template>
            <tr v-if="loadingLocalities === false && localities.length === 0">
              <td class="py-5" colspan="10">
                <p class="text-center">No localities could be found.</p>
              </td>
            </tr>
          </table>
        </table-wrapper>
      </panel>
    </loader-container>
    <div class="mb-5">
      <small>
        <strong class="capitalize">avg fee</strong> &amp;
        <strong class="capitalize">street value</strong> figures are calculated using the commission fee
        <strong>you</strong> have set.
      </small>
    </div>
    <div class="mb-5">
      <small class="mb-5">Although we endeavour to maintain the accuracy and relevance of the data contained within {{systemName}}, the data set is derived from Land Registry and occasionally there may be missing data which could affect the displayed results.</small>
      <br><br>
      <small class="mb-5">This analysis may include multiple streets with the same name in the selected area.</small>
    </div>
  </div>
</template>

<script>
import LocationYearly from '@/components/LocationYearly'
import RegionService from '@/services/RegionService'
import MoneyUtils from '@/MoneyUtils'
import LocationMap from '@/components/LocationMap'
import LocationTransactions from '@/components/LocationTransactions'
import CampaignSelector from '@/components/CampaignSelector'
import PostcodeService from '@/services/PostcodeService'
import ToolTip from '@/components/ToolTip'
import ModalSmall from '@/components/ModalSmall'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'LocalitySummary',
  components: {
    LocationYearly,
    LocationMap,
    LocationTransactions,
    CampaignSelector,
    ToolTip,
    ModalSmall,
  },

  mounted () {
    this.getTopPerformingLocalities()
  },

  data () {
    return {
      loadingLocalities: false,
      localitySort: '-locality_value_pa',
      localities: [],
      regionPropertyCounts: {},
      regionJointLocalityCounts: {},
      campaignSelectorData: null,
      campaignSelectorPosition: {
        x: 0,
        y: 0
      },
      tableKeyCount: 0,
      regionFiveYearPercentageIncrease: 0,
      showOverLimit: false,
      showDuplicateArea: false,
      campaignDuplicateAreaBtn: false
    }
  },

  computed: {
    ...mapGetters(['currentRegion']),
    ...mapState(['propertyTypeFilter', 'system', 'selectedRegions']),
    systemName() {
      return this.system ? this.system.name : 'Fee Street'
    },
    tableKey() {
      return 'table-' + this.tableKeyCount
    }
  },

  methods: {
    moneyFormat: MoneyUtils.format,

    sortLocality (sort) {
      let descending = this.localitySort.startsWith('-')
      let key = descending
        ? this.localitySort.substr(1, this.localitySort.length)
        : this.localitySort

      if (sort === key) {
        this.localitySort = descending ? sort : '-' + sort
        return
      }

      this.localitySort = '-' + sort
    },

    getTopPerformingLocalities () {
      this.loadingLocalities = true
      let regions = this.currentRegion === 'combined' ? this.selectedRegions : this.currentRegion.id
      RegionService.getTopPerformingLocalities(
        regions,
        this.propertyTypeFilter,
        this.localitySort
      ).then(({ data }) => {
        this.localities = data
        this.localities.forEach(l => {
          this.$set(l, 'transactions', [])
          this.$set(l, 'transactionView', false)
        })
        this.loadingLocalities = false
        this.getPropertyCounts()
        this.regionFiveYearPercentageIncrease = this.localities.length > 0 ? this.localities[0].region_percentage_increase : 0
      })
    },

    getLocalityTransactions (locality) {
      let regions = this.currentRegion === 'combined' ? this.selectedRegions : this.currentRegion.id
      return Promise.all([
        RegionService.getLocalityTransactions(regions, locality.name, this.propertyTypeFilter, locality.pc_district),
        RegionService.getYearlyLocalityData(regions, locality.name, this.propertyTypeFilter, locality.pc_district)
      ]).then(([transactions, yearly]) => {
        this.$set(locality, 'transactions', transactions.data)
        this.$set(locality, 'yearlyData', yearly.data)
      })
    },

    getPropertyCounts () {
      if (this.currentRegion !== 'combined') {
        return
      }

      let postcodeRegions = {}
      this.localities.forEach(locality => {
        if (postcodeRegions[locality.postcode_area] === undefined) {
          postcodeRegions[locality.postcode_area] = {
            postcodes: [],
            isSector: locality.area_type === 'sector'
          }
        }
        postcodeRegions[locality.postcode_area].postcodes.push(locality.pc_unit)
      })

      for (var postcodeArea in postcodeRegions) {
          let area = postcodeArea
          var postcodesArray = postcodeRegions[postcodeArea].postcodes;
          var postcodeSector = postcodeRegions[postcodeArea].isSector ? postcodeArea : null
          this.regionPropertyCounts[area] = {}
          PostcodeService.propertyCounts(postcodesArray, 'locality', postcodeSector)
            .then(({ data }) => {
              this.regionPropertyCounts[area] = data.counts
              this.regionJointLocalityCounts[area] = data.joint_locality_counts
              this.tableKeyCount++
            })
      }
    },

    /**
     * Get all applicable transactions for a locality and display them on the map.
     *
     * @param locality Locality information.
     */
    toggleTransactions (locality) {
      if (locality.transactionView) {
        locality.transactionView = false
        return
      }


      this.getLocalityTransactions(locality).then(() => {
        locality.transactionView = true
      })
    },

    openCampaignSelector(event, pcDistrict, pcSector, location, locality, count) {
      this.campaignSelectorData = {
        pcDistrict,
        pcSector,
        location,
        locality,
        count
      }

      this.campaignSelectorPosition.y = event.pageY - 60
      this.campaignSelectorPosition.x = document.documentElement.clientWidth - event.pageX + 20
    },

    setLocationHasCampaign () {
      this.loadingLocalities = false
      for (let i = 0; i < this.localities.length; i++) {
        if (this.localities[i].pc_district === this.campaignSelectorData.pcDistrict &&
          this.localities[i].name === this.campaignSelectorData.location) {
            this.localities[i].in_campaign = true
            break;
          }
      }
    },

    showOverlimitAlert () {
      this.loadingLocalities = false
      this.showOverLimit = true
    },
    showDuplicateAreaAlert() {
      this.loadingLocalities = false
      this.showDuplicateArea = true
    }
  },

  watch: {
    selectedRegions () {
      this.getTopPerformingLocalities()
    },

    propertyTypeFilter () {
      this.getTopPerformingLocalities()
    },

    localitySort () {
      this.getTopPerformingLocalities()
    }
  }
}
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 100%;
  min-height: 35rem;
}
</style>
