<template>
<div class="flex">
  <div class="w-full">
    <div class="w-full text-gray-600 text-center md:text-left overflow-hidden mt-6 mb-8">
      <ul class="w-full">
        <li
          v-for="(campaign, i) in campaigns"
          :key="i"
          @click="$emit('input', campaign)"
          class="flex items-center float-left mb-2 mr-4 px-3 py-2 border rounded-lg cursor-pointer"
          :class="[
            value === campaign ? 'border-accent text-gray-700' : '',
            hasSelectedRegions(campaign) ? 'bg-white' : 'bg-gray-100 text-gray-200'
          ]"
        >
          <div>{{ formatCampaignLabel(campaign) }}</div>
          <div v-if="campaign.active" class="w-2 h-2 ml-2 rounded-full bg-green-600"></div></li>
        <li @click="showSelectCompaignType=true" class="flex items-center float-left mb-2 mr-3 px-3 py-2 text-accent cursor-pointer">
          <icon name="plus" class="w-5 h-5 mr-2" />
          <div class="hover:underline">Add New Campaign</div>
        </li>
      </ul>
    </div>
  </div>

  <modal-small v-if="showSelectCompaignType" @close="showSelectCompaignType = false">
    <div class="w-full flex flex-wrap items-center">
      <div class="mx-auto pt-10 text-2xl">Choose Your Campaign Type</div>
    </div>
    <div class="w-full h-full flex flex-wrap items-center">
      <div class="flex flex-col items-center px-5 py-8 cursor-pointer mx-auto" @click="chooseCampaignType(false)">
        <div class="pointer-events-none overflow-hidden mb-2 mx-auto border-2">
            <img height="150" width="210" src="/images/default_campaign.png" />
        </div>
        <p>Default Campaign</p>
      </div>
      <div class="flex flex-col items-center px-5 py-8 cursor-pointer mx-auto" @click="chooseCampaignType(true)">
        <div class="pointer-events-none overflow-hidden mb-2 mx-auto border-2">
            <img height="150" width="210" src="/images/anniversary_campaign.png" />
        </div>
        <p>Anniversary Card</p>
      </div>
    </div>
  </modal-small>
</div>
</template>

<script>
import ModalSmall from '@/components/ModalSmall'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'CampaignPicker',

  components: {
    ModalSmall,
  },

  props: {
    value: null,
    campaigns: {
      default: () => { [] },
      type: Array
    }
  },

  data () {
    return {
      showSelectCompaignType: false,
    }
  },

  computed: {
    ...mapState(['selectedRegions']),
    ...mapGetters(['currentRegion'])
  },

  methods: {
    chooseCampaignType (anniversaryFlag) {
      this.$emit('newCampaign', anniversaryFlag)
      //close select module
      this.showSelectCompaignType = false
    },
    hasSelectedRegions (campaign) {
      return campaign.regions.filter(region => this.selectedRegions.includes(region.id)).length > 0
    },
    formatCampaignLabel (campaign) {
      let campaignLabel = campaign.name
      if (campaign.regions.length > 2) {
        return campaignLabel + ' - (Multiple Areas)'
      } else if (campaign.regions.length > 0) {
        let areaList = campaign.regions.map(region => region.postcode).join(', ')

        return campaignLabel + ' - ' + areaList
      }

      return campaignLabel
    }
  }
}
</script>

<style scoped>
.tab {
  position:relative;
}

.tab:after {
  @apply rounded shadow;
  content: "";
  position:absolute;
  width:100%;
  height: 100%;
  top:1px;
  left:0;
  z-index:-1;
}
</style>
