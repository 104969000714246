<template>
  <div>
    <panel class="flex flex-col md:flex-row">
      <div class="w-full md:h-full md:w-auto flex justify-around md:flex-col md:mr-5">
        <div class="w-full flex items-center px-3 py-5 md:border-b">
          <icon name="location-marker" class="-ml-4 text-accent w-10 h-10" />
          <div>
            <div class="text-gray-600">{{ selectedRegions.length > 1 ? 'Regions' : 'Region' }}</div>
            <div class="text-accent text-2xl font-bold">{{ regionList }}</div>
          </div>
        </div>

        <div class="w-full px-3 py-5 mt-3 md:mt-0">
          <loader-container :loading="isLoadingPropertySalesCount">
            <div class="md:text-2xl font-bold">{{ propertySales }}</div>
            <div class="text-sm md:text-base text-gray-600">Properties sold in the last 5 years</div>
          </loader-container>
        </div>
      </div>
      <div class="w-full flex items-center justify-center">
        <div class="w-full">
          <p v-if="sectorRegions.length > 0" class="w-full mb-4 text-gray-700">
            <strong>Please note:</strong> The highlighted area outlines cover whole postcode districts.
            Postcode sector regions ({{ sectorList }}) may be smaller than the defined area.
          </p>
          <gmap-map
            ref="map"
            class="map min-h-35"
            :center.sync="mapCenter"
            :zoom="10"
            :options="{}"
          >
          </gmap-map>
        </div>
      </div>
    </panel>
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'
import { mapGetters, mapState } from 'vuex'
import RegionService from '@/services/RegionService'

export default {
  name: 'AreaCovered',

  data () {
    return {
      mapCenter: {
        lat: 51.509865,
        lng: -0.118092
      },
      propertySales: null,
      isLoadingPropertySalesCount: false,
      regionPolygons: [],
      bounds: null
    }
  },

  computed: {
    ...mapGetters(['currentRegion']),
    ...mapState(['propertyTypeFilter', 'accountRegions', 'selectedRegions']),

    google: gmapApi,

    selectedRegionObjects() {
      return this.accountRegions.filter(region => this.selectedRegions.includes(region.id))
    },

    districtRegions() {
      return this.selectedRegionObjects.filter(region => !region.sector)
    },

    sectorRegions() {
      return this.selectedRegionObjects.filter(region => region.sector)
    },

    regionList() {
      return this.districtRegions.concat(this.sectorRegions).map(region => {
        return region.postcode
      }).join(', ')
    },

    sectorList() {
      return this.sectorRegions.map(region => {
        return region.postcode
      }).join(', ')
    }
  },

  mounted () {
    this.fetchMapPolygon()
    this.fetchPropertySales()
  },

  methods: {
    fetchMapPolygon () {
      this.$refs.map.$mapPromise.then(map => {

        // Remove any existing polygons
        this.regionPolygons.forEach(regionPolygon => {
          regionPolygon.setMap(null)
        })

        this.regionPolygons = []

        this.bounds = new window.google.maps.LatLngBounds()

        this.selectedRegionObjects.forEach(region => {
          var regionPolygon = new window.google.maps.Data();

          RegionService.getGeoJson(region.id).then(({ data }) => {
            const feats = regionPolygon.addGeoJson(data)
            this.fitToBoundaryGeometry(feats, map)
          })

          regionPolygon.setStyle({
            strokeColor: '#F25757',
            strokeWeight: 1,
            strokeOpacity: region.sector ? 0.5 : 1,
            fillColor: '#FAC1C1',
            fillOpacity: region.sector ? 0.25 : 0.5
          })

          this.regionPolygons.push(regionPolygon)
          regionPolygon.setMap(map)
        })
      })
    },

    fitToBoundaryGeometry (features, map) {
      features.forEach(feature => {
        feature.getGeometry().forEachLatLng(latLng => {
          this.bounds.extend(latLng)
        })
      })

      map.fitBounds(this.bounds)
    },

    /**
     * Get the number of property sales for the current region.
     */
    fetchPropertySales () {
      this.isLoadingPropertySalesCount = true
      let regions = this.currentRegion === 'combined' ? this.selectedRegions : this.currentRegion.id
      RegionService.getPropertySales(regions, this.propertyTypeFilter).then(response => {
        this.propertySales = response.data.toLocaleString('en-GB')
        this.isLoadingPropertySalesCount = false
      })
    }
  },

  watch: {
    selectedRegions () {
      this.fetchMapPolygon()
      this.fetchPropertySales()
    },

    propertyTypeFilter () {
      this.fetchPropertySales()
    }
  }
}
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 100%;
}
</style>
