import AccountService from '@/services/AccountService'

export default {
  getRegions ({ commit }) {
    return new Promise((resolve, reject) => {
      AccountService.getRegions().then(({ data }) => {
        commit('setRegions', data)
        resolve()
      }).catch(reject)
    })
  },

  getProfile ({ commit }) {
    return new Promise((resolve, reject) => {
      AccountService.getProfile().then(({ data }) => {
        commit('setProfile', data)
        resolve()
      }).catch(reject)
    })
  },

  getEcosystemProfile ({ commit }) {
    return new Promise((resolve, reject) => {
      AccountService.getEcosystemProfile().then(({ data }) => {
        commit('setEcosystemProfile', data)
        resolve()
      }).catch(reject)
    })
  },

  // eslint-disable-next-line
  switchOffice ({}, officeId) {
    AccountService.switchOffice(officeId).then(() => {
      window.location.reload()
    })
  },

  updateRegion ({ commit }, region) {
    commit('updateRegion', region)
  },

  setSelectedRegions ({ commit }, regionIds) {
    commit('setSelectedRegions', regionIds)
  },

  setSelectedRegion ({ commit }, regionId) {
    let regionIds = regionId ? [regionId] : []

    commit('setSelectedRegions', regionIds)
  },

  toggleSelectedRegion ({ commit }, regionId) {
    commit('toggleSelectedRegion', regionId)
  },

  toggleAllSelectedRegions ({ commit }) {
    commit('toggleAllSelectedRegions')
  },

  updatePropertyTypeFilter ({ commit }, type) {
    commit('updatePropertyTypeFilter', type)
  },

  getSystem ({ commit }) {
    return new Promise((resolve, reject) => {
      AccountService.getSystem().then(({ data }) => {
        commit('setSystem', data)
        resolve()
      }).catch(reject)
    })
  }
}
