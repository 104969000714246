<template>
  <div class="w-96 border-l pl-10">
    <h3 class="uppercase text-sm text-gray-500 mb-4">Campaign Summary:</h3>
    <loader class="relative" size="sm" v-if="loadingPropertyCounts" />
    <div class="flex flex-col" v-else>
      <div class="flex items-center mb-6">
        <div class="rounded-full flex items-center justify-center w-12 h-12 bg-gray-100 mr-2">
          <icon name="detached" class="w-8 h-8 text-accent" />
        </div>
        <div class="ml-4">
          <div>
            <span class="text-2xl font-bold">{{ propertyCountTotal }}</span> Properties
          </div>
          <small>in selected locations</small>
        </div>
      </div>

      <hr class="my-4">

      <div class="flex items-center mb-6">
        <div class="rounded-full flex items-center justify-center w-12 h-12 bg-green-100 mr-2">
          <icon name="sterling" class="w-8 h-8 text-green-600" />
        </div>
        <div class="ml-4">
          <div>
            <span class="text-2xl font-bold">{{ propertyCurrentYearCountTotal }}</span> Properties
          </div>
          <small>with selected Anniversaries this year</small>
          <div class="mt-2">
            <span class="text-2xl font-bold">{{ moneyFormat(pricePerCard * (propertyCurrentYearCountTotal + seedCardCountTotal))  }}</span> per Annum
          </div>
        </div>
      </div>

      <div class="flex items-center mb-6">
        <div class="rounded-full flex items-center justify-center w-12 h-12 bg-green-100 mr-2">
          <icon name="sterling" class="w-8 h-8 text-green-600" />
        </div>
        <div class="ml-4">
          <div>
            <span class="text-2xl font-bold">{{ propertyCurrentMonthCountTotal }}</span> Properties
          </div>
          <small>with selected Anniversaries this month</small>
          <div>
            <span class="text-2xl font-bold">{{ moneyFormat(pricePerCard * (propertyCurrentMonthCountTotal + seedCardCountTotal))  }}</span> this Month
          </div>
        </div>
      </div>

      <hr class="my-4">

      <div class="uppercase text-sm text-gray-500">Cost Per Item: <span class="text-black">{{ moneyFormat(pricePerCard) }}</span></div>

      <div class="flex mt-4">
        <div v-if="campaign.active && campaign.lastOrderTimestamp" class="uppercase text-sm text-gray-500 mb-4 mr-6"> Last Print Date: <br>
          {{ dateFormat(campaign.lastOrderTimestamp, 'dd/MM/yyyy') }}
        </div>
        <div v-if="campaign.active && campaign.nextOrderTimestamp" class="uppercase text-sm text-gray-500 mb-4"> Next Print Date: <br>
          {{ dateFormat(campaign.nextOrderTimestamp, 'dd/MM/yyyy') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import MoneyUtils from '@/MoneyUtils'
import RegionService from '@/services/RegionService'
import {DateTime} from "luxon";


export default {
  name: 'AnniversaryCampaignSummary',
  props: ["region","campaign", 'pricePerCard'],
  data () {
    return {
      loadingPropertyCounts: false,
      propertyCountTotal: 0,
      propertyCurrentYearCountTotal: 0,
      propertyCurrentMonthCountTotal: 0,
      seedCardCountTotal: 0,
    }
  },
  computed: {
  },
  mounted () {
    this.getCounts()
  },
  methods: {
    moneyFormat: MoneyUtils.format,
    getCounts() {
      this.loadingPropertyCounts = true
      RegionService.getAnniversaryCounts(this.region.id, this.campaign.id)
        .then(({ data }) => {
          if(data.result) {
            this.propertyCountTotal = data.propertyCountTotal
            this.propertyCurrentYearCountTotal = data.propertyCurrentYearCountTotal
            this.propertyCurrentMonthCountTotal = data.propertyCurrentMonthCountTotal
          }
          this.loadingPropertyCounts = false
        })
    },
    dateFormat: (timestamp, format) => {
      return DateTime.fromSeconds(timestamp).toFormat(format)
    }
  },
  watch: {
    'campaign.anniversaryItems': {
      deep: true,
      handler: function () {
        this.getCounts()
      }
    }
  }
}
</script>

