import find from 'lodash/find'

export default {
  currentRegion (state) {
    if (state.route.name.indexOf('region') !== -1) {
      if (state.route.params.id === 'combined') {
        return 'combined'
      }
      return find(state.accountRegions, r => r.id === parseInt(state.route.params.id))
    }

    return {}
  },

  regionPostcodes (state) {
    return state.accountRegions.map(region => {
      return { postcode: region.postcode }
    })
  },

  currentPage (state) {
    return state.route.name
  }
}
